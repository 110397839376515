
import SwiperClass, { Navigation, Manipulation } from "swiper";
import { mapState } from "vuex";

import { multiSortMixin } from "~/mixins";

const WIDGET = "CURRENT_REFINEMENTS";

export default {
  mixins: [multiSortMixin],

  props: { forMobile: { type: Boolean, default: false } },

  data: () => ({ isLastElement: false, isAway: false, swiper: null }),

  computed: {
    ...mapState("productsSearch", ["currentPageResults"]),
    swiperClass() {
      return this.forMobile ? "mobile-swiper" : "desktop-swiper";
    },
    swiperNextNavClass() {
      return `plp-refinements-next-${this.forMobile ? "mobile" : "desktop"}`;
    },
    swiperPrevNavClass() {
      return `plp-refinements-prev-${this.forMobile ? "mobile" : "desktop"}`;
    },
    swiperOptions() {
      return {
        slidesPerGroup: 1,
        slidesPerView: "auto",
        loop: false,
        loopFillGroupWithBlank: false,
        modules: [Navigation, Manipulation],
        navigation: {
          nextEl: `.${this.swiperNextNavClass}`,
          prevEl: `.${this.swiperPrevNavClass}`,
        },
        on: {
          reachEnd: () => {
            this.isLastElement = true;
          },
          fromEdge: swiper => {
            if (!swiper.isEnd) {
              this.isLastElement = false;
            }
          },
        },
      };
    },
    items() {
      return this.multiSort(
        this.currentPageResults?.filterSummary ?? [],
        this.currentPageResults?.facets ?? [],
        "field",
        "field"
      );
    },
  },

  watch: {
    items(value) {
      if (value.length !== 0) {
        this.$nextTick(() => {
          this.swiper?.update?.();
        });
      }
    },
  },

  mounted() {
    this.swiper = new SwiperClass(
      `.swiper.plp-selected-refinements.${this.swiperClass}`,
      this.swiperOptions
    );
  },

  destroyed() {
    this.swiper?.destroy?.();
  },

  methods: {
    refine(item) {
      this.$store.dispatch("productsSearch/refine", {
        widget: WIDGET,
        facet: item,
      });
    },
    getFilterLabel({ value }) {
      return typeof value === "object"
        ? `${value.rangeLow} - ${value.rangeHigh}`
        : value;
    },
    getURL(item) {
      return `${this.$route.path}?${this.$productsSearch.createURL(
        WIDGET,
        item
      )}`;
    },
  },
};
