
import { gql } from "graphql-tag";

import VariationPrismic from "./VariationPrismic";
import VariationVariant from "./VariationVariant";
import VariationSearchspring from "./VariationSearchspring";

import { getProductCatalogueV2 } from "./fragment";

export default {
  name: "ProductCatalogueV2",

  components: {
    VariationPrismic,
    VariationVariant,
    VariationSearchspring,
  },

  inject: {
    uid: { default: "" },
    pageType: { default: "" },
  },

  props: {
    slice: { type: Object, required: true },
    index: { type: Number, required: true },
    context: { type: Object, default: () => {} },
  },

  computed: {
    handle() {
      return this.$route.params?.handle;
    },
    query() {
      const pageType = this.pageType?.toLowerCase();

      return gql`
        query ${pageType}($uid: String!, $lang: String!,  $lazy: Boolean = true) {
           ${this.pageType}:${pageType}(uid: $uid, lang: $lang) {
                slices: body {
                  ... ${this.pageType}_product_catalogue_v2
                }
              }
            }
        ${getProductCatalogueV2(this.pageType)}
      `;
    },
  },

  methods: {
    async getPrismicData() {
      const { [this.pageType]: data } = await this.$getPrismicData({
        query: this.query,
        variables: { uid: this.uid },
      });

      return data?.slices?.[this.index] ?? {};
    },
  },
};
