
import { mapState } from "vuex";

export default {
  name: "ListOfProducts",

  props: { context: { type: Object, default: null } },

  async fetch() {
    await this.$store.dispatch("productsSearch/start", {
      queryPath: this.$route.fullPath.replace(this.$route.path, ""),
      collection: this.$route.params.handle,
      pageSize: this.hitsPerPage,
      facetKeyValues: this.facetKeyValues,
    });
  },

  computed: {
    ...mapState(["siteConfig", "appSettings"]),
    viewAll() {
      return this.$route.query.view_all == 1;
    },
    hitsPerPage() {
      return this.viewAll
        ? 1000
        : this.$device.isMobile
        ? this.context.bannerConfig?.productTileBanners
          ? 17
          : 18
        : this.context.bannerConfig?.productTileBanners
        ? 11
        : 12;
    },
    facetKeyValues() {
      const facetMapping = this.appSettings.l2_plp_facet_mapping ?? [];
      const rules = this.context?.collection?.ruleSet?.rules ?? [];
      const facetsMap = this.siteConfig.facetsMap ?? {};

      // #TODO make it dynamic for any field
      const keyValues = rules
        .filter(rule => rule.condition.startsWith("size"))
        .reduce((acc, rule) => {
          const facet = facetMapping.find(
            facet => facet.facet_key === rule.condition
          );

          const [key, value] = (facet?.facet_value ?? rule.condition).split(
            ":"
          );

          return [...acc, { key, value }];
        }, []);

      let { facet, facetValue } = this.$route.params;

      const matchedFacet = Object.values(facetMapping).find(item => {
        return item.facet_key === facetValue;
      });

      if (matchedFacet) {
        facetValue = matchedFacet.facet_value;
      }

      return [
        ...keyValues,
        facet && facetValue && { key: facetsMap[facet], value: facetValue },
      ].filter(Boolean);
    },
  },

  beforeDestroy() {
    this.$store.dispatch("productsSearch/dispose");
  },
};
