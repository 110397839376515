
import { mapState } from "vuex";
import VueSlider from "vue-slider-component/dist-css/vue-slider-component.umd.min.js";

import "vue-slider-component/dist-css/vue-slider-component.css";
import "vue-slider-component/theme/default.css";

const WIDGET = "REFINEMENT_LIST";

export default {
  components: { VueSlider },

  props: {
    facet: { type: Object, required: true },
  },

  data() {
    return {
      open: false,
      range: this.facet.range,
    };
  },

  computed: {
    ...mapState("productsSearch", ["initalPageResult"]),
    canRefine() {
      return (
        this.facet.range.length == 2 &&
        this.facet.range[0] !== this.facet.range[1]
      );
    },
    initalPageResultFacet() {
      return this.initalPageResult?.facets.find(
        ({ field }) => field === this.facet.field
      );
    },
    min() {
      return this.initalPageResultFacet?.range[0];
    },
    max() {
      return this.initalPageResultFacet?.range[1];
    },
  },

  watch: {
    "facet.facet_active": {
      handler(value) {
        this.open = this.open ? this.open : Boolean(value);
      },
      immediate: true,
    },
  },

  created() {
    this.$nuxt.$on("clear-all", () => {
      this.open = false;
    });
  },

  methods: {
    refine(value) {
      if (
        value[0] !== this.facet.range[0] ||
        value[1] !== this.facet.range[1]
      ) {
        this.$store.dispatch("productsSearch/refine", {
          widget: WIDGET,
          facet: { ...this.facet, value },
        });
      }
    },
    markValue() {
      return { [this.max]: this.max, [this.min]: this.min };
    },
  },
};
