
import { mapState } from "vuex";

const WIDGET = "CLEAR_REFINEMENT";

export default {
  computed: {
    ...mapState("productsSearch", ["currentPageResults"]),
    hasRefinements() {
      return this.currentPageResults?.filterSummary?.length > 0 ?? false;
    },
    filterURL() {
      return `${this.$route.path}?${this.$productsSearch.createURL(WIDGET)}`;
    },
  },

  methods: {
    refine() {
      this.$store.dispatch("productsSearch/refine", { widget: WIDGET });
      this.$nuxt.$emit("clear-all");
    },
  },
};
