
import { mapState } from "vuex";
import ClickOutside from "vue-click-outside";

export default {
  directives: { ClickOutside },

  data: () => ({ active: false }),

  computed: {
    ...mapState("productsSearch", ["currentPageResults"]),
    sortingOptions() {
      return (this.currentPageResults?.sorting?.options ?? []).map(option => ({
        id: `${option.field}-${option.direction}`,
        ...option,
      }));
    },
    currentSorting() {
      return (
        this.sortingOptions.find(option => option.active === 1) ??
        this.sortingOptions[0]
      );
    },
    items() {
      return this.sortingOptions.filter(
        option => option.label !== this.currentSorting.label
      );
    },
  },

  methods: {
    sort(item) {
      this.active = false;
      if (
        item.field === this.currentSorting.field &&
        item.direction === this.currentSorting.direction
      ) {
        // sort option not chnaged
        return;
      }

      this.$store.dispatch("productsSearch/sort", item);
    },
    sortByValue(selectedValue) {
      this.sort(
        this.sortingOptions.find(option => option.id === selectedValue)
      );
    },
    hide() {
      this.active = false;
    },
  },
};
