
import { mapGetters } from "vuex";

const WIDGET = "REFINEMENT_LIST";

export default {
  props: {
    checkbox: { type: Boolean, default: true },
    facet: { type: Object, required: true },
  },

  data: () => ({ open: false }),

  computed: {
    ...mapGetters("productsSearch", ["getFacetValues"]),
    items() {
      return this.getFacetValues(this.facet.field);
    },
  },

  watch: {
    "facet.facet_active": {
      handler(value) {
        this.open = this.open ? this.open : Boolean(value);
      },
      immediate: true,
    },
  },

  created() {
    this.$nuxt.$on("clear-all", () => {
      this.open = false;
    });
  },

  methods: {
    refine(value) {
      this.$store.dispatch("productsSearch/refine", {
        widget: WIDGET,
        facet: { ...this.facet, value },
      });
    },
  },
};
