
import { difference } from "ramda";
import { mapGetters, mapState } from "vuex";

export default {
  inject: { uid: { default: "" } },

  props: { banners: { type: Array, default: () => [] } },

  data: () => ({ topBarHeight: 100 }),

  computed: {
    ...mapState("productsSearch", ["page", "currentPageResults"]),
    ...mapGetters("productsSearch", ["hits", "isFirstPage", "isLastPage"]),
    ...mapState("app", ["topBannerHeight"]),
    items() {
      if (this.banners.length !== 2) {
        return this.hits;
      }

      let hits = [...this.hits];
      const bannerDiff = this.isMobile ? [9, 9] : [11, 7];

      for (
        let i = 6, bannerIndex = 0, totalItems = this.hits.length;
        i < totalItems;
        i += bannerDiff[bannerIndex],
          totalItems++, // Increase the toatalItems length to include the inserted banners
          bannerIndex = (bannerIndex + 1) % 2
      ) {
        hits.splice(i, 0, {
          id: `__banner__${i}`,
          ...this.banners[bannerIndex],
        });
      }

      return hits;
    },
    totalResults() {
      return this.currentPageResults?.pagination.totalResults ?? 0;
    },
    pagination() {
      return `Showing ${this.hits.length} of ${this.totalResults} products`;
    },
    filterSummary() {
      return this.currentPageResults?.filterSummary ?? [];
    },
  },

  watch: {
    "$route.query": {
      handler(newQuery, oldQuery) {
        const currentPage =
          this.currentPageResults?.pagination?.currentPage ?? 1;
        // if not first page OR query changed then no scroll
        if (currentPage !== 1 || newQuery.query !== oldQuery.query) {
          return;
        }

        this.$nextTick(() => {
          // current result page is the first page
          this.scrollToBack(this.$refs.productContainer?.firstChild);
        });
      },
    },
    hits: [
      function scrollOnItemsChange(newItems, oldItems) {
        const currentPage =
          this.currentPageResults?.pagination?.currentPage ?? 1;
        if (currentPage === 1) {
          return;
        }

        this.$nextTick(() => {
          // run in next tick so that all product tiles are rendered
          const productTiles = this.$refs.productTile ?? [];
          this.scrollToBack(productTiles[oldItems.length]?.$el);
        });
      },
      function addItemsToGtm(newItems, oldItems) {
        const differenceList = difference(newItems, oldItems);

        if (differenceList.length !== 0) {
          this.triggerGtmEvent(differenceList);
        }
      },
    ],
  },

  methods: {
    getProduct(item) {
      return {
        ...item,
        variants: item.variants.map(variant => ({
          ...variant,
          variantsCount: item.variants_count,
          compareAtPrice: variant.compare_at_price,
          productId: variant.product_id,
          selectedOptions: variant.selected_options.reduce(
            (acc, { name, value }) => ({ ...acc, [name]: { name, value } }),
            {}
          ),
        })),
      };
    },
    getSelectedVariant(item) {
      return this.getProduct(item).variants[0];
    },
    refineNext() {
      this.$store.dispatch("productsSearch/showMore");
    },
    refinePrevious() {
      this.$store.dispatch("productsSearch/showPrevious");
    },
    scrollToTop(top) {
      globalThis?.scrollTo({ top, left: 0, behavior: "smooth" });
    },
    scrollToBack(firstElement) {
      if (firstElement == null) {
        return;
      }

      const { top } = firstElement?.getBoundingClientRect?.() ?? { top: 0 };
      const offset = document.documentElement.scrollTop;
      this.scrollToTop(top + offset - this.topBarHeight - this.topBannerHeight);
    },
    triggerGtmEvent(items) {
      this.$gtm.gtagEvent("view_item_list", {
        item_list_id: this.uid,
        item_list_name: this.uid,
        items: items.map((item, index) => {
          const selectedVariant = this.getSelectedVariant(item);

          return {
            item_id: selectedVariant.sku,
            shopify_product_id: item.id,
            item_name: item.title,
            currency: "AUD",
            discount:
              selectedVariant?.compare_at_price && selectedVariant?.price
                ? Number(selectedVariant?.compare_at_price) -
                  Number(selectedVariant?.price)
                : 0,
            index: index + 1,
            item_brand: item.metafields?.brand_list ?? "",
            item_category: item.metafields?.product_category ?? "",
            item_list_id: this.uid,
            item_list_name: this.uid,
            item_variant: selectedVariant.title,
            price: selectedVariant.price,
            quantity: 1,
          };
        }),
      });
    },
  },
};
