
export default {
  name: "ImageItem",

  props: { slice: { type: Object, required: true } },

  computed: {
    imgMobile() {
      return this.slice?.primary?.image?.mobile;
    },
    desktopWidth() {
      return (
        this.slice?.primary?.image?.large_screen?.dimensions?.width || 1280
      );
    },
    imgMobileProps() {
      const imgDimensions = this.imgMobile?.dimensions;

      return {
        src: this.imgMobile?.url,
        alt: this.imgMobile?.alt,
        ...this.getImageDimensions(imgDimensions, 400),
      };
    },
  },

  methods: {
    getImgDesktopProps(width) {
      const img =
        this.slice?.primary?.image?.large_screen ??
        this.slice?.primary?.image?.desktop;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        ...this.getImageDimensions(imgDimensions, width),
      };
    },
  },
};
