
import ProductList from "./ProductList";
import ProductCarousel from "./ProductCarousel";
import { slugify } from "~/utils";

export default {
  components: { ProductList, ProductCarousel },

  props: {
    slice: { type: Object, required: true },
    products: { type: Array, default: () => [] },
    isFetching: { type: Boolean, default: false },
    source: { type: String, default: "" },
  },

  computed: {
    productsProps() {
      return {
        products: this.products.map(product => ({
          ...product,
          source: this.source,
        })),
      };
    },
    listType() {
      return this.slice.primary?.list_type == false ? "gridList" : "carousel";
    },
    swiperClass() {
      const title = slugify(
        this.$prismic.asText(this.slice.primary.title) ?? ""
      );
      return `${title}-${this.source}-products-swiper`;
    },
  },

  watch: {
    products(value) {
      this.$store.dispatch("products/setRecommendedProduct", value?.[0]);
    },
  },
};
