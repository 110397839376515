
export default {
  props: {
    handle: { type: String, default: "" },
    selectedVariantId: { type: [String, Number], default: "" },
    reviewsAverage: { type: [String, Number], default: "0" },
    reviewsCount: { type: [String, Number], default: "0" },
  },
  computed: {
    rating() {
      return Number(this.reviewsAverage);
    },
  },
};
