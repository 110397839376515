
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("productsSearch", ["currentPageResults"]),
    keySuffix() {
      return this.$route.query.query ?? this.$route.params.handle;
    },
  },

  methods: {
    visibilityChanged(isVisible) {
      if (isVisible) {
        this.$store.dispatch("app/setRefinementListVisiblity", isVisible);
      }
    },
  },
};
