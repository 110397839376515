
export default {
  props: {
    product: { type: Object, required: true },
    productIndex: { type: Number, required: true },
  },

  computed: {
    selectedVariant() {
      return this.product.selectedVariant ?? this.product.variants[0];
    },
  },

  methods: {
    visibilityChanged(isVisible) {
      if (!this.product.source.includes("searchspring-") || !isVisible) {
        return;
      }

      const [, tag] = this.product.source.split("searchspring-");
      this.$store.dispatch("recommendations/setVisibleProductsIds", {
        tag,
        productId: this.product.id,
      });
    },
  },
};
