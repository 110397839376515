
import { mapState } from "vuex";

export default {
  props: { banners: { type: Array, default: () => [] } },

  computed: {
    ...mapState("app", ["isFilterPanelOpen"]),
    productListClass() {
      return this.isFilterPanelOpen ? "hidden  lg:block" : "";
    },
  },
};
