
import { mapGetters, mapState } from "vuex";

export default {
  data: () => ({
    showViewAll: false,
    otherOverlayScreen: false,
  }),

  computed: {
    ...mapState("app", [
      "isFilterPanelOpen",
      "activeMenuIndex",
      "isMegaMenuOpen",
      "topBannerHeight",
      "isRefinementListVisible",
    ]),
    ...mapState("productsSearch", ["currentPageResults"]),
    ...mapGetters("productsSearch", ["hits"]),
  },

  watch: {
    isMegaMenuOpen(newVal) {
      this.watchMegaMenuProp(newVal);
    },
    activeMenuIndex(newVal) {
      if (this.$device.isDesktop) {
        this.watchMegaMenuProp(newVal > -1);
      }
    },
  },

  methods: {
    watchMegaMenuProp(propVal) {
      if (propVal) {
        this.otherOverlayScreen = true;
      } else if (this.activeMenuIndex < 0) {
        setTimeout(() => {
          this.otherOverlayScreen = false;
        }, 1000);
      }
    },
    toggleFilterPanel() {
      if (this.isMobileOrTablet) {
        this.$store.dispatch("app/TOGGLE_FILTER_PANEL");
      }
    },
  },
};
