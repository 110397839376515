export default {
  data: () => ({ yotpoIsReady: false }),

  methods: {
    checkForYotpo() {
      setTimeout(() => {
        if (
          window.yotpoWidgetsContainer?.yotpo_widget_initializer?.length === 1
        ) {
          this.yotpoIsReady = true;
          window.yotpoWidgetsContainer.initWidgets(false);
        } else {
          console.log("Checking for Yotpo...");
        }

        if (this.yotpoIsReady === false) this.checkForYotpo();
      }, 500);
    },
  },
};
