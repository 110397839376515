import { render, staticRenderFns } from "./PlpAllRefinements.vue?vue&type=template&id=20aea960&scoped=true&"
import script from "./PlpAllRefinements.vue?vue&type=script&lang=js&"
export * from "./PlpAllRefinements.vue?vue&type=script&lang=js&"
import style0 from "./PlpAllRefinements.vue?vue&type=style&index=0&id=20aea960&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20aea960",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PlpListRefinement: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/PlpListRefinement.vue').default,PlpPriceRangeRefinement: require('/home/runner/work/snooze-vue-storefront/snooze-vue-storefront/components/plp/PlpPriceRangeRefinement.vue').default})
