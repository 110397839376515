
import { mapGetters, mapActions } from "vuex";

import ProductsFetch from "./ProductsFetch";

export default {
  components: { ProductsFetch },

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: () => {} },
  },

  data: () => ({ products: [] }),

  async fetch() {
    const { tag, max_products_limit: limit } = this.slice.primary;

    const payload = { tag, limit, sku: this.context.sku };
    await this.$store.dispatch("recommendations/fetchProducts", payload);

    this.products = this.getProducts(tag);

    await this.trackBeacon({ type: "render", tag });
  },

  fetchOnServer: false,

  computed: {
    ...mapGetters("bag", ["lines"]),
    ...mapGetters("recommendations", ["getProducts", "getVisibleProductIds"]),
    visibleProductIds() {
      return this.getVisibleProductIds(this.slice.primary.tag);
    },
  },

  mounted() {
    const unwatch = this.$watch("visibleProductIds", productIds => {
      if (productIds.length > 0) {
        // Add setTimeout so that all products are in visible list for current impression
        setTimeout(() => {
          this.trackBeacon({ type: "impression", tag: this.slice.primary.tag });
        }, 250);
        unwatch();
      }
    });
  },

  methods: {
    ...mapActions("recommendations", ["trackBeacon"]),
  },
};
