
import { mapState } from "vuex";

export default {
  name: "HeroSection",

  props: {
    slice: { type: Object, required: true },
    context: { type: Object, default: null },
  },

  computed: {
    ...mapState("app", ["isFilterPanelOpen"]),

    bannerImgProps() {
      const bannerImg = this.context?.bannerConfig.heroBanner.image;
      const img = this.$device.isMobile
        ? bannerImg?.mobile
        : this.$device.isTablet
        ? bannerImg?.tablet
        : bannerImg;
      const imgDimensions = img?.dimensions;

      return {
        src: img?.url,
        alt: img?.alt,
        width: imgDimensions?.width,
        ...this.getImageDimensions(imgDimensions, 900),
      };
    },
    heroBanner() {
      return this.context?.bannerConfig?.heroBanner;
    },
  },
};
